



















































































































































































































import {namespace} from 'vuex-class';
import {Component} from 'vue-property-decorator';
import {AUTH_STORE_NAME, AuthStoreActions} from '@/store/auth.store';
import {ROUTE_HOME, ROUTE_USER_CREATED} from '@/router/routes';
import {email, integer, minLength, minValue, required} from 'vuelidate/lib/validators';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {mixins} from 'vue-class-component';
import User from "@/models/User.model";
import {passwordCriteria} from "@/misc/PasswortCriterias";
import {Gender} from "@/enum/UserTitle.enum";
import {UserDegree} from "@/enum/UserDegree";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {},
  mixins: [validationMixin],
  validations: {
    user: {
      gender: {
        required
      },
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        minValue: minValue(0),
        integer
      },
      address: {
        city: {
          required
        },
        houseNr: {
          required,
        },
        street: {
          required
        },
        zip: {
          required,
          minValue: minValue(0),
          integer
        }
      },
      password: {
        required,
        minLength: minLength(8)
      },
    },
  }
})
export default class RegisterView extends mixins(ErrorMessageHandlerMixin) {
  @AuthStore.Action(AuthStoreActions.REGISTER_RESTAURATEUR)
  private registerAction!: (payload: { email: string, password: string, companyName: string }) => Promise<User | null>;

  /**
   * criterias that needs to fullfilled in order to set the password
   * @private
   */
  private passwordCriteria = [...passwordCriteria];

  /**
   * user model that is used on the inputs for storing the values
   * @private
   */
  private user: User = User.parseFromObject({gender: Gender.MALE});

  /**
   * different degreeOptions
   * @private
   */
  private dOptions = [UserDegree.PROF, UserDegree.PHD];

  /**
   * different gender options
   * @private
   */
  private gOptions = [Gender.MALE, Gender.FEMALE, Gender.DIVERSE];

  /**
   * returns translated genders based on the gender enum
   * @private
   */
  private get genderOptions() {
    return this.gOptions.map((n: Gender) => this.$t('GENERAL.GENDER.' + n));
  }

  /**
   * flag that shows and hides the chars of the password field
   */
  private hidePassword: boolean = true;

  /**
   * Loading State bool
   * @private
   */
  private isLoading: boolean = false;

  /**
   * redirects the user to the home view if he/she is logged in
   * @private
   */
  private created() {
    // Redirect to home if already logged in:
    if (this.$isLoggedIn()) {
      this.$router.push({name: ROUTE_HOME});
    }
  }

  /**
   * Validate the password input regarding the password criteria an base criteria (e. g. required)
   * @param value
   */
  public validatePasswordInput(value: string) {
    // base criteria
    this.triggerValidation('user.password');
    // special password criteria
    this.passwordCriteria.forEach((criterion) => criterion.fullfilled = criterion.regEx.test(value));
  }

  /**
   * checks if every criteria is checked
   */
  private get criteriaChecked() {
    return this.passwordCriteria?.filter((c) => !c.fullfilled).length > 0;
  }

  /**
   * Check if Form is Valid
   * if valid try to Register a new Account and listen for API Responses
   * If Invalid return so Vuelidate handles Errors first
   * @private
   */
  private async doRegistration() {
    // TODO: logs the user to show the entered inputs
    console.log(this.user);

    // Trigger validation
    this.$v.$touch();

    // Form is Invalid return here
    if (this.$v.$invalid) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS');
      return;
    }

    if(this.criteriaChecked) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS');
      return;
    }

    // Form is Valid try to Register a new Account
    try {
      // Set Loading to true
      this.isLoading = true;

      // logs in the user, checks if the user has the correct role
      // await this.registerAction({
      //   email: this.email, password: this.password, companyName: this.company
      // });

      // Registration Successful toast
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_SUCCESSFUL');

      // pushes the application to the home view
      await this.$router.push({name: ROUTE_USER_CREATED});
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 409: // Email or Company already exists
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_EMAIL_OR_COMPANY_ALREADY_EXISTS');
            break;
          case 422: // Wrong Format in Credentials
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_WRONG_CREDENTIALS');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.REGISTRATION_ERROR');
        }
      });
    } finally {
      this.isLoading = false;
    }
  }
}
